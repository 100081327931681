module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-yandex-metrika/gatsby-browser'),
      options: {"plugins":[],"trackingId":"51760400","webvisor":false,"trackHash":true,"version":2},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":590,"sizeByPixelDensity":true,"linkImagesToOriginal":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
